<template>
        <div :class="[
            color ? 'card-shadow-' + color :'card-shadow-primary',
            'widget-chart',
            'widget-chart2',
            'card-btm-border',
            'text-left',
            color ? 'border-' + color : 'border-primary',
            'card'
            ]">
            <div class="widget-chat-wrapper-outer d-block">
                <div class="box-image">
                    <img class="rounded-circle image-right card-image" :src="getImg" :alt="title" />
                </div>
                <div class="widget-chart-content data-box">

                    <h6 class="widget-subheading">{{title}}</h6>
                        <div class="widget-numbers mb-0 w-100">


                            <div class="widget-chart-flex">
                                <div class="fsize-2" v-if="formatHtl">
                                    {{value|formatHtl}}
                                </div>
                                <div class="fsize-2" v-else>
                                    {{value.toFixed(2)}}
                                </div>

                            </div>
                        </div>
                </div>
            </div>
        </div>
</template>

<script>
export default {
    name: "ImageCard",
    props: [
        "title",
        "value",
        "image",
        "formatHtl",
        "color"
    ],
    data() {
        return {
            images: {
                globe: require('@/assets/images/globe.png'),
                turkey: require('@/assets/images/tr.png'),
                greece: require('@/assets/images/gr.png'),
                pakistan: require('@/assets/images/pk.png'),
                africa: require('@/assets/images/africa.png'),
            }
        }
    },
    computed: {
        getImg() {
            switch (this.image) {
                case 0:
                    return this.images.globe;
                case 1:
                    return this.images.turkey;
                case 2:
                    return this.images.greece;
                case 3:
                    return this.images.pakistan;
                case 4:
                    return this.images.africa;
                default:
                    return this.images.globe;
            }
        }
    }
}
</script>

<style scoped>
    .mt-100 {
        top: -2rem;
        position: relative;
        overflow: visible;
        max-height: 0px;
    }

    .box-image {
        float: right;
        width: 20%;
        max-width: 20%;
    }

    .data-box {
        float: left;
        width: 80%;
        padding-right: 20px;
    }

    .image-right {
        float: right;
    }

    .card-image {
        width: 3rem;
    }
</style>