<template>
    <div v-if="page">
        <h2>{{  $t('real-estate-title') }}</h2>
        <div class="row justify-content-around">
            <div class="col-12 col-md-12 col-lg-4 col-xl-3 mb-3">
                <plain-card :title="$t('total-circulated')" :value.sync="total" :format-htl="true" />
            </div>
        </div>
        <div class="row justify-content-around">
            <div class="col-12 col-md-12 col-lg-6 col-xl-4 mb-3">
                <plain-card :title="$t('total-in-packages')" :value.sync="page.globalStats.packageActive" :format-htl="true" />
            </div>
        </div>
        <div class="row justify-content-around" >
            <div class="col-6 col-md-6 col-lg-4 col-xl-4 mb-3">
                <plain-card :title="$t('total-shares-A')" :value.sync="page.globalStats.sharesA" :format-htl="true" color="danger" />
            </div>

            <div class="col-6 col-md-6 col-lg-4 col-xl-4 mb-3">
                <plain-card :title="$t('total-shares-B')" :value.sync="page.globalStats.shares + page.globalStats.held" :format-htl="true" color="danger" />
            </div>

        </div>
        <div class="row justify-content-around">

            <div class="col-12 col-md-12 col-lg-6 col-xl-4 mb-3">
                <plain-card :title="$t('reserved-value')" :value=1028000.0000 color="success" />
            </div>
            <div class="col-12 col-md-12 col-lg-6 col-xl-4 mb-3">
                <image-card :title="$t('global-budget')" :value.sync="hotelBudget" :image=0 :format-htl="true" color="success"  />
            </div>
            <div class="col-12 col-md-12 col-lg-6 col-xl-4 mb-3" v-if="page.viewer.country === 'GR' || page.viewer.username === 'FOUNDER'">
                <image-card :title="$t('greece-budget')" :value.sync="elHotelBudget" :image=2 :format-htl="true" color="success"  />
            </div>
        </div>

        <div class="row justify-content-around">
            <div class="col-12 col-md-12 col-lg-6 col-xl-4 mb-3 justify-content-center">
                <plain-card :title="$t('secured-price')" :value.sync="securedPrice" :format-htl="true" class="w-auto" color="none" />
            </div>
        </div>
    </div>

</template>

<script>
import PlainCard from "@/components/Information/PlainCard";
import ImageCard from "@/components/Information/ImageCard";
import gql from "graphql-tag";
export default {
    name: "RealEstatePage",
    components: {ImageCard, PlainCard},
    data() {
        return {
            page: null,
            africa: ['CD', 'UG', 'ZM', 'TZ', 'CG']
        }
    },
    computed: {
        total() {
            if (this.page) {
                return this.page.globalStats.held + this.page.globalStats.shares + this.page.globalStats.sharesA + this.page.globalStats.packageActive
            }
            return 0
        },
        hotelBudget() {
            if (this.page) {
                return this.page.globalStats.hotelBudget * 1e4 - 1258e11 - 270e11
            }
            return 0
        },
        elHotelBudget() {
            if (this.page) {
                return this.page.globalStats.elHotelBudget * 1e4 + 795e9
            }
            return 0
        },
        securedPrice() {
            if (this.page) {
                return (1e12 * this.page.globalStats.hotelBudget) / (this.page.globalStats.held + this.page.globalStats.shares + this.page.globalStats.sharesA + this.page.globalStats.packageActive)
            }
            return 0.21
        },
    },
    apollo: {
        page: {
            query: gql`query{doc(id:"hotels"){id,content,title},globalStats{held,shares,sharesA,packageActive,hotelBudget,trHotelBudget,elHotelBudget},viewer{id,country,username}}`,
            update: data => data,
        }
    }
}
</script>

<style scoped>

</style>